class window.Responsive

  # Constructor
  constructor: ->

    @behavior()

  # End of Constructor

  # Logic of Checking what device is
  @isPhone: ->
    $('html').hasClass "phone-screen"
  @isSmartphone: ->
    $('html').hasClass "smartphone-screen"
  @isTablet: ->
    $('html').hasClass "tablet-screen"
  @isDesktop: ->
    $('html').hasClass "desktop-screen"
  # End of Logic of Checking what device is

  # defineScreen
  defineScreen: ->
    width = $(window).width()
    screen = ''

    if width <= 800
      screen = "smartphone-screen"
    else if width >= 801 && width <= 1024
      screen = "tablet-screen"
    else
      screen = "desktop-screen"

    $('html').addClass screen
    screen
  # end of defineScreen

  # Behavior
  behavior: ->

    # Reload if the screen has changed
    @actualScreen = @defineScreen()

    checkScreen = =>
      if @actualScreen != @defineScreen()
        location.reload()

    lazyHandler = _.debounce checkScreen, 250
    $(window).on "resize", lazyHandler
    # End of Reload if the screen has changed

  # End of Behavior

##########################################
# End of Class Responsive
##########################################

$ ->

  Responsive = new window.Responsive
